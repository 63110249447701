import ArtIcon from '@svg/art.svg';
import CameraIcon from '@svg/camera.svg';
import ComedyMask from '@svg/comedy-mask.svg';
import DanceIcon from '@svg/dance.svg';
import GamingIcon from '@svg/gaming.svg';
import HeritageIcon from '@svg/heritage.svg';
import KidsIcon from '@svg/kids.svg';
import MusicIcon from '@svg/music.svg';
import TheaterIcon from '@svg/theater.svg';
import VideoIcon from '@svg/video.svg';
import {
  Accessibility,
  AccessibilitySign,
  AfricanTree,
  Airplane,
  AppleHalf,
  Bank,
  Basketball,
  Bed,
  Bicycle,
  BinMinusIn,
  Bonfire,
  Bookmark,
  BookmarkSolid,
  Calendar,
  Check,
  ChurchSide,
  CinemaOld,
  ClosedCaptionsTag,
  CloudDownload,
  CoffeeCup,
  Community,
  CurveArray,
  Cutlery,
  DesignPencil,
  Download,
  EmojiBlinkLeft,
  Eye,
  EyeClosed,
  FacebookTag,
  Farm,
  Filter,
  Fish,
  Flower,
  Gamepad,
  Google,
  GraduationCap,
  Group,
  Gym,
  HomeAlt,
  IceCream,
  InfoCircle,
  InfoCircleSolid,
  Instagram,
  Language,
  Leaf,
  Learning,
  Link,
  Linkedin,
  MailOut,
  Map,
  MapPin,
  MediaImage,
  Menu,
  Minus,
  MoneySquare,
  MusicDoubleNote,
  MusicNote,
  NavArrowDown,
  NavArrowLeft,
  NavArrowRight,
  Neighbourhood,
  Palette,
  Parking,
  Phone,
  Pinterest,
  PizzaSlice,
  Play,
  Plus,
  Rain,
  RssFeed,
  Search,
  SeaWaves,
  SendDiagonal,
  SendMail,
  ShareIos,
  Shirt,
  ShoppingBag,
  Skateboarding,
  SortDown,
  Sparks,
  Star,
  SunLight,
  Swimming,
  Telegram,
  Tiktok,
  Train,
  Tree,
  Twitter,
  Upload,
  Walking,
  WarningCircle,
  WhiteFlag,
  Wolf,
  Xmark,
  Yoga,
  Youtube,
} from 'iconoir-react';

const icons = {
  DANCE: DanceIcon,
  HERITAGE: HeritageIcon,
  CAMERA: CameraIcon,
  GAMING: GamingIcon,
  KIDS: KidsIcon,
  ART: ArtIcon,
  MUSIC: MusicIcon,
  THEATER: TheaterIcon,
  VIDEO: VideoIcon,
  AIRPLANE: Airplane,
  BONFIRE: Bonfire,
  INFO_CIRCLE_SOLID: InfoCircleSolid,
  NAV_ARROW_DOWN: NavArrowDown,
  WHITE_FLAG: WhiteFlag,
  BICYCLE: Bicycle,
  MENU: Menu,
  PLAY: Play,
  DOWNLOAD: Download,
  LANGUAGE: Language,
  NAV_ARROW_LEFT: NavArrowLeft,
  NAV_ARROW_RIGHT: NavArrowRight,
  GRADUATION_CAP: GraduationCap,
  NEIGHBOURHOOD: Neighbourhood,
  BASKETBALL: Basketball,
  FLOWER: Flower,
  MAP: Map,
  PARKING: Parking,
  BANK: Bank,
  SHOPPING_BAG: ShoppingBag,
  SEARCH: Search,
  RSS_FEED: RssFeed,
  LEAF: Leaf,
  MEDIA_IMAGE: MediaImage,
  CURVE_ARRAY: CurveArray,
  SEND_MAIL: SendMail,
  SPARKS: Sparks,
  CALENDAR: Calendar,
  FILTER: Filter,
  CLOSED_CAPTIONS_TAG: ClosedCaptionsTag,
  SORT_DOWN: SortDown,
  CHECK: Check,
  MAP_PIN: MapPin,
  MAIL_OUT: MailOut,
  PHONE: Phone,
  WOLF: Wolf,
  ACCESSIBILITY_SIGN: AccessibilitySign,
  PIZZA_SLICE: PizzaSlice,
  APPLE_HALF: AppleHalf,
  ACCESSIBILITY: Accessibility,
  HOME_ALT: HomeAlt,
  CLOUD_DOWNLOAD: CloudDownload,
  SHARE_IOS: ShareIos,
  PINTEREST: Pinterest,
  YOUTUBE: Youtube,
  WALKING: Walking,
  SHIRT: Shirt,
  SEND_DIAGONAL: SendDiagonal,
  EMOJI_BLINK_LEFT: EmojiBlinkLeft,
  BED: Bed,
  TIKTOK: Tiktok,
  LINKEDIN: Linkedin,
  TWITTER: Twitter,
  AFRICAN_TREE: AfricanTree,
  SKATEBOARDING: Skateboarding,
  COFFEE_CUP: CoffeeCup,
  FACEBOOK: FacebookTag,
  GOOGLE: Google,
  TELEGRAM: Telegram,
  BOOKMARK: Bookmark,
  BOOKMARK_SOLID: BookmarkSolid,
  MUSIC_DOUBLE_NOTE: MusicDoubleNote,
  GYM: Gym,
  INSTAGRAM: Instagram,
  EYE: Eye,
  EYE_CLOSED: EyeClosed,
  XMARK: Xmark,
  MINUS: Minus,
  PLUS: Plus,
  LINK: Link,
  BIN_MINUS_IN: BinMinusIn,
  WARNING_CIRCLE: WarningCircle,
  UPLOAD: Upload,
  FACEBOOK_TAG: FacebookTag,
  GAMEPAD: Gamepad,
  ICE_CREAM: IceCream,
  MUSIC_NOTE: MusicNote,
  COMMUNITY: Community,
  DESIGN_PENCIL: DesignPencil,
  INFO_CIRCLE: InfoCircle,
  SWIMMING: Swimming,
  RAIN: Rain,
  FISH: Fish,
  GROUP: Group,
  LEARNING: Learning,
  CHURCH_SIDE: ChurchSide,
  TRAIN: Train,
  YOGA: Yoga,
  STAR: Star,
  SUN_LIGHT: SunLight,
  CUTLERY: Cutlery,
  CINEMA_OLD: CinemaOld,
  MONEY_SQUARE: MoneySquare,
  SEA_WAVES: SeaWaves,
  TREE: Tree,
  PALETTE: Palette,
  FARM: Farm,
  COMEDY_MASK: ComedyMask,
};

export type IconType = keyof typeof icons;

export interface Props {
  icon: IconType;
  className?: string;
  strokeWidth?: number;
}

export default function Icon({ icon, className, strokeWidth = 2 }: Props) {
  const IconElement =
    // @ts-expect-error - Replaced icon component with list input. This supports old icon selections in prepr.
    icons[icon] || icons[icon.toUpperCase().replaceAll('-', '_')];

  if (!IconElement) {
    console.warn(`Icon ${icon} not found`);

    return null;
  }

  return <IconElement strokeWidth={strokeWidth} className={className} />;
}
