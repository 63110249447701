export const BG_DEFAULT = 'white';
export const BG_ALTERNATIVE = 'gray';
export const BG_BRAND = 'brand';
export const BG_PRIMARY = 'primary';
export const BG_SECONDARY = 'secondary';
// Map our locales to the Prepr i18n locales
export const DEFAULT_LOCALE = 'nl';
export const LOCALES = {
  nl: 'nl-NL',
  en: 'en-GB',
} as const;
export const ITEMS_PER_PAGE = 12;
export const ALTERNATE_ITEMS_PER_PAGE = 9;
export const EVENTS_ON_LOCATION = 6;

export const DEFAULT_MAP_CENTER = {
  lat: 51.58926539798087,
  lon: 4.775798471572234,
  radius: 4,
};
export const STORE_LOCATION = {
  lat: 51.58870171421307,
  lon: 4.777059116127888,
};

export const ROUTES = {
  LocationOverviewPage: {
    en: 'locations',
    nl: 'locaties',
  },
  NewsOverviewPage: {
    en: 'blogs',
    nl: 'blogs',
  },
  EventOverviewPage: {
    en: 'events',
    nl: 'evenementen',
  },
  RouteOverviewPage: {
    en: 'routes',
    nl: 'routes',
  },
  ExplorePage: {
    en: 'explore',
    nl: 'explore',
  },
  SearchPage: {
    en: 'search',
    nl: 'zoeken',
  },
  a11y: {
    en: 'accessibility',
    nl: 'toegankelijkheid',
  },
  MapPage: {
    en: 'map',
    nl: 'plattegrond',
  },
  SharedListPage: {
    en: 'list',
    nl: 'lijst',
  },
  EventCreatedPage: {
    en: 'event-registered',
    nl: 'evenement-aangemeld',
  },
  CookieDeclarationPage: {
    en: 'cookie-declaration',
    nl: 'cookieverklaring',
  },
  ZigzagCampaignPage: {
    en: 'zigzagculture',
    nl: 'zigzagcultuur',
  },
} as const;

export const FOCUSABLE_ELS_SELECTOR =
  'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])';

export const PANEL_IDS = {
  main: 'main-panel-id',
  list: 'main-list-id',
  map: 'main-explore-id',
  search: 'main-search-id',
};

export const A11Y_PANEL_ID = 'a11y-panel-id';

export const DETAIL_PAGES = [
  'ContentPage',
  'EventPage',
  'LocationPage',
  'NewsPage',
  'RoutePage',
];

export const STORE_DETAIL_PAGES = ['EventPage', 'LocationPage', 'RoutePage'];

export const TIMETABLE_ID = 'opening-times-id';

export const DAYS = [
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
  'SUNDAY',
] as const;

export const BOOKMARK_COOKIE = 'bookmarks';
export const BOOKMARK_ID_COOKIE = 'bookmarkId';

export const FONT_SIZE_COOKIE = 'a11yFontSize';

export const STORE_DISABLED_SECTIONS = [
  'NewsletterSection',
  'EventRegistrationSection',
  'ExploreFormSection',
  'IframeSection',
  'CtaButtonsSection',
];
